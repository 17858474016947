import React, { createContext, useState } from 'react'

const MobileMenuContext = createContext<{
  isOpen: boolean
  open: () => void
  close: () => void
  secondLevelDropdownExpanded: string[]
  triggerSecondLevelDropdown: (url: string) => void
}>({
  isOpen: false,
  open() {},
  close() {},
  secondLevelDropdownExpanded: [],
  triggerSecondLevelDropdown() {}
})

const MobileMenuProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [
    secondLevelDropdownExpanded,
    setSecondLevelDropdownExpanded
  ] = useState<string[]>([])

  function open() {
    setIsOpen(true)
    document.body.style.overflow = 'hidden'
  }
  function close() {
    setIsOpen(false)
    document.body.style.overflow = 'visible'
  }
  function triggerSecondLevelDropdown(url: string) {
    setSecondLevelDropdownExpanded((prevState: string[]) => {
      if (prevState.includes(url)) {
        return prevState.filter(it => it !== url)
      } else {
        return [...prevState, url]
      }
    })
  }

  return (
    <MobileMenuContext.Provider
      value={{
        isOpen,
        open,
        close,
        secondLevelDropdownExpanded,
        triggerSecondLevelDropdown
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  )
}

export { MobileMenuContext, MobileMenuProvider }
