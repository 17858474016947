// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js": () => import("./../../../../node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/Directory/Directory.js" /* webpackChunkName: "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js" */),
  "component---src-components-site-burger-burger-mdx": () => import("./../../../src/components/site/Burger/Burger.mdx" /* webpackChunkName: "component---src-components-site-burger-burger-mdx" */),
  "component---src-components-templates-event-event-tsx": () => import("./../../../src/components/templates/Event/Event.tsx" /* webpackChunkName: "component---src-components-templates-event-event-tsx" */),
  "component---src-components-templates-page-page-tsx": () => import("./../../../src/components/templates/Page/Page.tsx" /* webpackChunkName: "component---src-components-templates-page-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */)
}

